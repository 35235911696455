<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-8 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='`${sidebarBgStyle}`'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li v-for='(sidebarLink, index) in sidebarLinks'
          :key='`sidebar-link-${sidebarLink.title}-${index}`'
          class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link v-if='sidebarLink.actionType==="open_internal_route"'
            :to='{ name: sidebarLink.link }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </router-link>
          <a v-else-if='sidebarLink.actionType==="open_external_link"'
            :href='sidebarLink.link'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0'>
      <div class='max-w-2xl py-8 lg:py-16 '>
        <div class='flex flex-row gap-x-8 mb-4 lg:mb-8'>
          <h1 class='text-3xl font-bold'>나의 초록</h1>
          <button
            v-if='isAbstractSubmissionPeriod'
            @click='gotoNewAbstract'
            class='py-3 px-8 text-sm uppercase text-center bg-gray-900 text-white rounded-md hover:shadow-md opacity-80 hover:opacity-100'>
            {{ newAbstractSubmissionButtonText }}
          </button>
        </div>
        <abstract-submission-preview v-for='abstract in myAbstracts'
          :key='`my-abstract-${abstract.id}`'
          :abstract='abstract'
          class='border rounded-md mb-8' />
        <div v-if='hasNoAbstracts && isAbstractSubmissionPeriod'
          class='rounded-md bg-gray-100 py-8 px-4 mb-8 text-center text-gray-600'>
          {{ noAbstractSubmissionsMessage }}
        </div>
        <div v-else-if='hasNoAbstracts && !isAbstractSubmissionPeriod'>
          <p class='mb-2'>{{ abstractSubmissionPeriodOverMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import AbstractSubmissionPreview   from '@/components/kossoasc/AbstractSubmissionPreview.vue'
import dayjs from 'dayjs'

export default {
  name: 'AbstractSubmissions',
  components: {
    AbstractSubmissionPreview,
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
      'showingEventId',
      'eventConfigAbstractSubmissionDeadline',
      'showingEventAbstractSubmissionsConfigurations',
    ]),
    ...mapState('abstracts', [
      'myAbstracts',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    customBackgroundStyle () {
      return "background-color: #926699; background-image: url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/sidebar_image_v2.png); background-repeat: no-repeat; background-position: top; background-size: 100% auto;"
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    hasNoAbstracts () {
      return this.myAbstracts.length == 0
    },
    isAbstractSubmissionPeriod () {
      return dayjs(this.eventConfigAbstractSubmissionDeadline).isAfter(dayjs(new Date()))
    },
    abstractSubmissionPeriodOverMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionPeriodOverMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionPeriodOverMessage : 'Abstract submission deadline has passed.'
    },
    noAbstractSubmissionsMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations.noAbstractSubmissionsMessage) ? this.showingEventAbstractSubmissionsConfigurations.noAbstractSubmissionsMessage : 'You have not yet submitted any abstracts.'
    },
    newAbstractSubmissionButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations.newAbstractSubmissionButtonText) ? this.showingEventAbstractSubmissionsConfigurations.newAbstractSubmissionButtonText : 'Submit A New Abstract'
    },
    sidebarLinks () {
      return [
        {
          "link": "AbstractRegistrationInformation",
          "title": "초록 접수 안내",
          "actionType": "open_internal_route"
        },
        {
          "link": "AbstractSubmissions",
          "title": "초록 접수",
          "actionType": "open_internal_route"
        },
        {
          "link": "OralPresentationGuidelines",
          "title": "구연 안내",
          "actionType": "open_internal_route"
        },
        {
          "link": "PosterPresentationGuidelines",
          "title": "포스터 안내",
          "actionType": "open_internal_route"
        }
      ]
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'logout',
    ]),
    ...mapActions('abstracts', [
      'getMyAbstracts',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToMain () {
      this.$router.push({name: 'Main'})
    },
    goToAbstractSubmissionGuideline () {
      this.$router.push({name: 'AbstractSubmissionGuideline'})
    },
    gotoNewAbstract () {
      if (this.isAbstractSubmissionPeriod) {
        this.$router.push({name: 'AbstractSubmission', query: { abstract_submission_id: 0 }})
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.getMyAbstracts(this.showingEventId).then(() => {
          if (!this.myAbstracts.length) {
            this.$router.replace({name: 'AbstractSubmission'})
          }
        })
      }
    }).catch(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissions'} })
      } 
    })
  },
}
</script>

<style lang='scss' scoped>
.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md; 
}

@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}    
</style>
