<template>
  <div>
    <div class='bg-gray-100 text-center p-4 flex flex-row justify-between'>
      <h1 class='text-xl font-bold'>{{abstract.title}}</h1>
      <button
        v-if='isAbstractSubmissionPeriod'
        @click='gotoAbstract(abstract)'
        class='p-1 rounded-md'
        :class='isAbstractSubmissionPeriodPreviewClass'>
        <edit-icon />
      </button>
    </div>
    <div class='p-4'> 
      <div v-for='formField in abstractSubmissionFormFields'
        :key='formField.keyName'  
        class='flex flex-row justify-start items-start mb-2'>
        <label class='w-40 text-gray-500 uppercase flex-shrink-0'>{{ formField.name }}: </label>
        <div class='flex-grow'>{{fieldValue(formField.category, formField.keyName)}}</div>
      </div>
      <div class='flex flex-row justify-start items-start'>
        <label class='w-40 text-gray-500 uppercase flex-shrink-0'>파일 다운로드:</label>
        <div class='flex-grow'>
          <a :href='abstract.abstractFileUrl' target='_blank' class='underline'>
            {{abstract.title}} 다운로드
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EditIcon }   from 'vue-tabler-icons'
import { mapGetters } from 'vuex'
import camelCase      from 'lodash/camelCase'
import dayjs          from 'dayjs'

export default {
  name: 'AbstractSubmissionPreview',
  props: [
    'abstract',
  ],
  components: {
    EditIcon,
  },
  computed: {
    ...mapGetters('events', [
      'eventConfigAbstractSubmissionDeadline',
      'showingEventAbstractSubmissionsConfigurations',
    ]),
    abstractSubmissionFormFields () {
      return (this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionFormFields) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionFormFields.filter(formField => formField.keyName !== 'title' && formField.keyName !== 'abstract_file_url') : []
    },
    isAbstractSubmissionPeriod () {
      return dayjs(this.eventConfigAbstractSubmissionDeadline).isAfter(dayjs(new Date()))
    },
    isAbstractSubmissionPeriodPreviewClass () {
      return this.isAbstractSubmissionPeriod ? 'cursor-pointer hover:shadow-md border hover:border-gray-600' : ''
    },
  },
  methods: {
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          val = this.abstract[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.abstract[arr[0]]
        }
      }
      return val
    },
    gotoAbstract (abstract) {
      if (this.isAbstractSubmissionPeriod) {
        this.$router.push({name: 'AbstractSubmission', query: { abstract_submission_id: abstract.id }})
      }
    },

  },
}

</script>
